<template>
    <div class="order-details">
        <div class="top">
            <span>{{ statusInfo.statusText }}</span>
            <img :src="require('@/assets/' + statusInfo.icon + '.png')" alt v-if="statusInfo.icon" />
        </div>

        <div class="address-box">
            <img class="address-img" src="@/assets/address.png" alt />
            <div class="mid">
                <p>
                    {{ details.address_contacts }}
                    <span>{{ details.address_phone }}</span>
                </p>
                <p>{{ details.address_details }}</p>
            </div>
            <img class="right-img" src="@/assets/right.png" alt />
        </div>

        <div class="goods-list">
            <router-link tag="div" class="goods-item" :to="'/ShopGoodsDetails?types=1&goods_id=' + details.goods_id">
                <img :src="details.goods_image" alt />
                <div class="goods-info">
                    <p class="goods-name">{{ details.goods_name }}</p>
                    <div class="goods-spec" v-if="details.specs_item_value != ''">{{ details.specs_item_value }}</div>
                    <div class="flex-between">
                        <div class="goods-price">
                            ￥<span>{{ details.goods_price }}</span>
                        </div>
                        <div class="goods-num">×{{ details.goods_nums }}</div>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="total">
            <div class="total-row">
                <div class="left">商品总计</div>
                <div class="right">￥{{ details.goods_amount }}</div>
            </div>
            <div class="total-row">
                <div class="left">运费</div>
                <div class="right">￥{{ details.postage_amount }}</div>
            </div>
            <div class="total-row" v-if="details.order_status > 1">
                <div class="left">实付款</div>
                <div class="right">￥{{ details.pay_amount }}</div>
            </div>
        </div>
        <div class="info-box">
            <div class="info-box-title">订单信息</div>
            <div class="info-row">订单编号： {{ details.order_no }}</div>
            <div class="info-row">下单时间： {{ details.create_time }}</div>
            <template v-if="details.order_status > 1">
                <div class="info-row">下单金额： ￥{{ details.order_amount }}</div>
                <div class="info-row">支付方式： {{ details.pay_types | payTyps }}</div>
                <div class="info-row">付款时间： {{ details.pay_time }}</div>
                <div class="info-row">支付金额： ￥{{ details.pay_amount }}</div>
            </template>
            <template v-if="details.order_status > 2">
                <div class="info-row">发货时间： {{ details.delivery_time }}</div>
                <div class="info-row">快递公司： {{ details.logistics_company }}</div>
                <div class="info-row">
                    快递单号： {{ details.logistics_code }}
                    <span class="copy" v-clipboard:copy="details.logistics_code" v-clipboard:success="onCopy"
                        v-clipboard:error="onError">复制</span>
                </div>
            </template>
            <div class="info-row" v-if="details.order_status == 4">收货时间： {{ details.receive_time }}</div>
        </div>
        <!-- 公众号二维码 -->
        <we-chat-public-q-r-code></we-chat-public-q-r-code>
        <!-- 底部按钮 -->
        <div class="bot-btn" v-if="statusInfo && statusInfo.showBtn">
            <template v-for="(item_, index_) in statusInfo.btnArr">
                <div :class="['btn',{'red': item_.isActive}]" :key="index_" v-if="item_.isShow"
                    @click="action(item_.types)">{{ item_.text }}</div>
            </template>
        </div>
    </div>
</template>

<script>
    import {
        getGoodsOrderDetails,
        closeGoodsOrder,
        receiveGoodsOrder,
        deleteGoodsOrder,
    } from "@/http/api";
    export default {
        components: {},
        data() {
            return {
                userToken: "",
                order_id: "",
                details: {}, 
            };
        },
        mounted() {
            if (this.$route.query.order_id) {
                this.userToken = this.$LStorage.getItem("userToken").user_token;
                this.order_id = this.$route.query.order_id
                this.getGoodsOrderDetails();
            } else {
                this.$dialog.alert({
                    message: '订单商品页面参数异常,返回上一页看看',
                }).then(() => {
                    this.$router.go(-1);
                });
            }
        },
        methods: {
            async getGoodsOrderDetails() {
                const res = await getGoodsOrderDetails({
                    data: {
                        user_token: this.userToken,
                        order_id: this.order_id
                    }
                });
                this.details = res.data;
            },
            
            // 监听复制成功
            onCopy(e) {
                this.$toast.success("复制成功");
            },
            
            // 监听复制失败
            onError(e) {
                this.$toast.fail("复制失败");
            },
            
            action(types) {
                switch (types) {
                    case 'delete1':
                        this.$dialog.confirm({
                            title: '提示',
                            message: '确定要删除该订单吗？'
                        }).then(() => {
                            this.deleteGoodsOrder()
                        })
                        break;
                    case 'cancel':
                        this.$dialog.confirm({
                            title: '提示',
                            message: '确定要取消该订单吗？'
                        }).then(() => {
                            this.closeGoodsOrder()
                        })
                        break;
                    case 'toReceive':
                        this.$dialog.confirm({
                            title: '提示',
                            message: '请确保您已收到货物，收货后如需售后请联系客服',
                            confirmButtonText: '收到了',
                            cancelButtonText: '没收到',
                        }).then(() => {
                            this.receiveGoodsOrder()
                        })
                        break;
                    case 'after':
                        this.$router.push({
                            path: '/AfterSale?order_id=' + this.details.order_id
                        });
                        break;
                    case 'hasAfter':
                        break;
                    case 'toPay':
                        break;
                }
            },

            async deleteGoodsOrder() {
				let that = this;
                const res = await deleteGoodsOrder({
                    data: {
                        user_token: this.userToken,
                        order_id: this.details.order_id
                    }
                });
                if (res.code == 200) { 
					this.$toast.success({message:'删除成功', onClose:function(){
						that.$router.go(-1);
					}}); 
                } else {
                    this.$toast.fail(res.msgs);
                }
            },

            async closeGoodsOrder() {
                const res = await closeGoodsOrder({
                    data: {
                        user_token: this.userToken,
                        order_id: this.details.order_id
                    }
                });
                if (res.code == 200) {
                    this.$toast.success('取消成功');
                    this.getGoodsOrderDetails();
                } else {
                    this.$toast.fail(res.msgs);
                }
            },

            async receiveGoodsOrder() {
                const res = await receiveGoodsOrder({
                    data: {
                        user_token: this.userToken,
                        order_id: this.details.order_id
                    }
                });
                if (res.code == 200) {
                    this.$toast.success('确认成功');
                    this.getGoodsOrderDetails();
                } else {
                    this.$toast.fail(res.msgs);
                }
            }
        },
        computed: {
            statusInfo() {
                let status = this.details.order_status,
                    isService = this.details.is_service;
                switch (status) {
                    case 0:
                        return {
                            statusText: '已取消',
                                icon: 'tranbg',
                                showBtn: true,
                                btnArr: [{
                                    isActive: false,
                                    text: '删除订单',
                                    types: 'delete1',
                                    isShow: true
                                }]
                        }
                        break;
                    case 1:
                        return {
                            statusText: '待付款',
                                icon: 'tranbg2',
                                showBtn: true,
                                btnArr: [{
                                    isActive: true,
                                    text: '立即支付',
                                    types: 'toPay',
                                    isShow: false
                                }, {
                                    isActive: false,
                                    text: '取消订单',
                                    types: 'cancel',
                                    isShow: true
                                }]
                        };
                        break;
                    case 2:
                        let text = '申请售后',
                            types = 'after';
                        if (isService == 2) {
                            text = '已申请';
                            types = 'hasAfter';
                        }
                        return {
                            statusText: '待发货',
                                icon: 'tranbg3',
                                showBtn: true,
                                btnArr: [{
                                    isActive: false,
                                    text,
                                    types,
                                    isShow: true
                                }]
                        };
                        break;
                    case 3:
                        let text_ = '申请售后',
                            types_ = 'after',
                            isShow = true;
                        if (isService == 2) {
                            text_ = '已申请';
                            types_ = 'hasAfter';
                            isShow = false
                        }
                        return {
                            statusText: '待收货',
                                icon: 'tranbg4',
                                showBtn: true,
                                btnArr: [{
                                    isActive: true,
                                    text: '确认收货',
                                    types: 'toReceive',
                                    isShow
                                }, {
                                    isActive: false,
                                    text: text_,
                                    types: types_,
                                    isShow: true
                                }]
                        };
                        break;
                    case 4:
                        return {
                            statusText: '已完成',
                                icon: 'tranbg1',
                                showBtn: false,
                                btnArr: [{
                                    isActive: false,
                                    text: '删除订单',
                                    types: 'delete1',
                                    isShow: true
                                }]
                        };
                        break;
                    default:
                        return {
                            statusText: '未知',
                                icon: 'tranbg',
                                showBtn: false,
                        }
                }
            }
        },
        filters: {
            payTyps(data) {
                switch (data) {
                    case 1:
                        return "微信支付";
                        break;
                    case 3:
                        return "通联支付";
                        break;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .order-details {
        background: #f6f6f6;
        box-sizing: border-box;
        min-height: 100vh;
        padding-bottom: 1.3rem;

        * {
            box-sizing: border-box;
        }

        .top {
            height: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #e40011;
            color: #fff;
            font-size: 0.3rem;
            margin-bottom: 0.3rem;

            img {
                height: 0.7rem;
                margin-left: 0.2rem;
            }
        }

        .address-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 3vw;
            height: 1.5rem;
            background: #fff;
            margin-bottom: 0.25rem;

            .address-img {
                width: 0.35rem;
            }

            .right-img {
                width: 0.25rem;
                opacity: 0;
            }

            .mid {
                width: 80vw;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 0.28rem;

                p:first-child {
                    margin-bottom: 5px;
                }

                span {
                    font-size: 0.26rem;
                    color: #8a8a8a;
                    margin-left: 2em;
                }
            }
        }

        .goods-list {
            background: #fff;
            padding: 0.2rem 3vw;
            margin: 0.2rem;
            margin-bottom: 0.2rem;
            border-radius: 0.1rem;

            .goods-item {
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                    width: 24vw;
                    height: 24vw;
                    object-fit: cover;
                }

                .goods-info {
                    width: calc(100% - 26vw);
                    height: 24vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .goods-name {
                        font-size: 0.28rem;
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .goods-spec {
                        background: #f5f5f5;
                        border-radius: 5px;
                        padding: 0.1rem 0.15rem;
                        font-size: 0.24rem;
                        color: #848484;
                        // width: max-content;
                    }

                    .goods-num {
                        font-size: 0.24rem;
                    }

                    .goods-price {
                        font-size: 0.24rem;
                        color: #da1313;

                        span {
                            font-size: 0.3rem;
                            font-weight: bold;
                        }
                
   }
                }
            }
        }

        .total {
            margin: 0.2rem;
            border-radius: 0.1rem;
            overflow: hidden;
        }

        .total-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fff;
            font-size: 0.28rem;
            height: 1rem;
            padding: 0 0.2rem;
            border-bottom: #f1f1f1 solid 1px;

            &:last-child {
                border-bottom: none;
            }

            .right {
                color: #da1313;
            }
        }

        .info-box {
            background: #fff;
            padding: 0.2rem 0.3rem;
            border-radius: 0.1rem;
            width: 94vw;
            margin: 3vw 3vw 0.3rem;

            .info-box-title {
                font-size: 0.28rem;
                position: relative;
                margin-bottom: 0.2rem;
            }

            .info-box-title::before {
                position: absolute;
                content: "";
                background: #da1313;
                width: 4px;
                height: 16px;
                left: -8px;
                top: 2px;
                margin-bottom: 10px;
            }

            .info-row {
                font-size: 0.26rem;
                line-height: 2;

                .copy {
                    width: 0.8rem;
                    height: 0.38rem;
                    background: #da1313;
                    border-radius: 0.05rem;
                    color: #fff;
                    text-align: center;
                    line-height: 0.38rem;
                    display: inline-block;
                    margin-left: 0.2rem;
                    font-size: 0.22rem;
                }
            }
        }

        .qr-code-box {
            margin: 0 3vw;
            border-radius: 0.1rem;
        }

        .bot-btn {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1.12rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background-color: #fff;

            .btn {
                width: 1.4rem;
                height: 0.6rem;
                border-radius: 5px;
                border: 1px solid #c0c0c0;
                color: #585858;
                font-size: 0.26rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.35rem;

                &.red {
                    color: #da1313;
                    border: 1px solid #da1313;
                }
            }
        }
    }
</style>
